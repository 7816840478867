import React from 'react'
import {Link} from 'gatsby'

const sanitizePattern = new RegExp('^.*//[^/]+', '')

const SearchItemTitle = ({postItem, postItemMini}) =>
	<>
		{postItem && postItem._source &&
			<h2 className={`search-list-item-title ${postItemMini ? 'search-list-mini-item-title' : ''}`}>
				{postItem._source.permalink.indexOf('roubaixxl') >= 0 ?
					<a
						target="_blank" rel="noopener noreferrer" href={postItem._source.permalink}
						dangerouslySetInnerHTML={{__html:(postItem.highlight && postItem.highlight.post_title) ? postItem.highlight.post_title : postItem._source.post_title}}>
					</a> :
					<Link
						to={postItem._source.permalink && postItem._source.permalink.replace(sanitizePattern, '').replace('/roubaix/events/','/agenda/').replace('/roubaix/event/','/agenda/')}
						dangerouslySetInnerHTML={{__html: (postItem.highlight && postItem.highlight.post_title) ? postItem.highlight.post_title : postItem._source.post_title}}>
					</Link>
				}
			</h2>
		}
	</>

export default SearchItemTitle
