import { getIsPwaActive } from '../utils/functions'

export const matomo = () => {
	if(typeof window !== 'undefined'){
		let _paq = window._paq || []
		_paq.push([function() {
			var self = this;
			function getOriginalVisitorCookieTimeout() {
				var now = new Date(),
				nowTs = Math.round(now.getTime() / 1000),
				visitorInfo = self.getVisitorInfo();
				var createTs = parseInt(visitorInfo[2]);
				var cookieTimeout = 33696000; // 13 mois en secondes
				var originalTimeout = createTs + cookieTimeout - nowTs;
				return originalTimeout;
			}
			this.setVisitorCookieTimeout( getOriginalVisitorCookieTimeout() );
		}]);
		_paq.push(['setCustomVariable', 1, 'pwa', getIsPwaActive(), 'visit'])
		window._paq = _paq
	}
}