import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "reactstrap";

import logo from "../../img/logo-roubaix-blanc.svg";
import LanguageContext from "./../../state/context/LanguageContext";

const Footer = () => (
  <LanguageContext.Consumer>
    {({ language, translation }) => (
      <footer className="footer text-white bg-dark">
        <Container>
          <Row className="align-items-top justify-content-between">
            <Col md="5" lg="2">
              <address>
                <img className="mb-3" src={logo} alt="Ville de Roubaix" />
                <br />
                17, Grand' Place
                <br />
                CS 70737
                <br />
                59100 Roubaix Cedex 1<br />
              </address>
            </Col>
            <Col className="contact-access" md="7" lg="4">
              <ul className="list-unstyled d-flex">
                <li className="w-100">
                  <a
                    href="/appeler-laccueil"
                    className="d-block text-center px-3 border-right"
                  >
                    <i className="icon-phone d-block mb-3" />
                    {translation.call}
                  </a>
                </li>
                <li className="w-100">
                  <Link
                    to="/contact"
                    className="d-block text-center px-3 border-right"
                  >
                    <i className="icon-markunread d-block mb-3" />
                    {translation.contact}
                  </Link>
                </li>
                <li className="w-100">
                  <a
                    href="/signaler-un-probleme"
                    className="d-block text-center px-3"
                  >
                    <i className="icon-chat d-block mb-3" />
                    {translation.problem}
                  </a>
                </li>
              </ul>
            </Col>
            <Col>
              <p className="footer-nl-title">{translation.newsletterlabel}</p>
              <Link
                to="/la-newsletter-de-roubaix/"
                rel="noopener noreferrer"
                className="footer-nl-link btn btn-lg btn-primary"
              >
                {translation.subscribe}
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="w-100 pt-3 pb-3 bg-black footer-alt">
          <Container>
            <a
              className="footer-alt-link"
              href={translation.legalLink}
              title={translation.legal}
            >
              {translation.legal}
            </a>
            <a
              className="footer-alt-link"
              href="/professionnels/presse/"
              title={translation.presse}
            >
              {translation.presse}
            </a>
            <a
              className="footer-alt-link"
              href="/accessibilite"
              title={translation.footerAccessibility}
            >
              {translation.footerAccessibility}
            </a>
            <a
              className="footer-alt-link link-malentendant"
              href="/contact-malentendant-sourd"
              title={translation.footerMalentendant}
            >
              {translation.footerMalentendant}
            </a>
          </Container>
        </div>
      </footer>
    )}
  </LanguageContext.Consumer>
);

export default Footer;
