import React from 'react'
import { connect } from 'react-redux'

import {getLoading} from '../../state/reducers/loading'

const Loading = ({loading}) => 
	<>
		{loading.length > 0 && <div className="loading" />}
	</>

const mapStateToProps = state => ({
	loading: getLoading(state)
})

export default connect(mapStateToProps)(Loading)
