import locales from "./locales.json";

const getTranslations = languageSlug => {
  let translation = {};
  Object.keys(locales).map(stringObject => {
    translation[stringObject] = locales[stringObject]
      ? locales[stringObject][languageSlug]
        ? locales[stringObject][languageSlug]
        : locales[stringObject]["fr"]
        ? locales[stringObject]["fr"]
        : ""
      : "";
    return null;
  });

  return translation;
};

export default getTranslations;
