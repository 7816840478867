import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import i18n from "./../../i18n/i18n.json";
import * as loadingActions from "../../state/actions/loading";

import Config from "../../config/Config.json";
import Config_local from "../../config/Config_local.json";
import SearchQueryConfig from "./SearchQueryConfig.json";
import SearchMiniListItem from "./SearchMiniListItem";
import SearchPopularSearch from "./SearchPopularSearch";

const config = { ...Config, ...Config_local };
const searchMiniQuery = { ...SearchQueryConfig.queryDefault };

class SearchListMini extends Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      hidePopin: true,
      mysearchmini: "",
      totals: false,
      posts: 0,
      searchFinish: false,
      forceLaunch: false
    };

    //on bloque la boucle d'une touche laissée enfoncée
    this.onKeyDown = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.launchMiniSearch();
  }

  componentWillUnmount() {
    const { loadingOff } = this.props;

    loadingOff("SearchListMini");
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    (prevState.mysearchmini !== this.state.mysearchmini ||
      this.state.forceLaunch) &&
      this.launchMiniSearch();
  }

  searchListMiniChange = ({ target }) => {
    const { value } = target;

    this.setState({
      mysearchmini: value.trim(),
      searchFinish: false,
      forceLaunch: false
    });
  }

  searchListMiniSubmit = (e) => {
    e.preventDefault();
    this.launchMiniSearch();
  }

  searchListMiniOnKeyDown = () => {
    if (!this.onKeyDown) {
      this.onKeyDown = true;
    }
  }

  searchListMiniOnKeyUp = (e) => {
    this.onKeyDown = false;
    const { value } = e.target;

    if ( value.length > 2 ) {
      const { currentLanguage = "fr" } = this.props;
      const languageSearch =
        i18n[currentLanguage] && i18n[currentLanguage].searchPage
          ? i18n[currentLanguage].searchPage
          : "recherche";

      if (e.keyCode && e.keyCode === 13) {
        this.setState({
          hidePopin: true,
          searchFinish: false,
          forceLaunch: false
        });
        navigate(`/${languageSearch}/${value.trim()}`);
      } else {
        this.setState({
          mysearchmini: value.trim(),
          searchFinish: false,
          forceLaunch: false
        });
      }
    }
  }

  searchListMiniGoSearch = (e) => {
    e.preventDefault();

    const { currentLanguage = "fr" } = this.props;
    const languageSearch =
      i18n[currentLanguage] && i18n[currentLanguage].searchPage
        ? i18n[currentLanguage].searchPage
        : "recherche";

    this.setState({
      searchFinish: false,
      forceLaunch: false
    });
    navigate(`/${languageSearch}/${this.state.mysearchmini.trim()}`, {
      mySearch: this.state.mysearchmini.trim()
    });
  }

  searchListMiniOnClick = (e) => {
    (e.target.closest(".search-list-mini-alllink") ||
      !e.target.closest(".search-form")) &&
      this.setState({
        hidePopin: true,
        searchFinish: false,
        forceLaunch: false
      });
  }

  searchListMiniInputOnClick = (e) => {
    this.setState({
      forceLaunch: true,
      mysearchmini: this.state.mysearchmini.trim(),
      searchFinish: false
    });
  }

  launchMiniSearch() {
    const { loadingOn, loadingOff, currentLanguage = "fr" } = this.props;
    const { mysearchmini } = this.state;

    loadingOn("SearchListMini");

    if (
      mysearchmini &&
      !this.onKeyDown &&
      (mysearchmini.length > 2 || mysearchmini === "*")
    ) {
      searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[0].match.post_title.query = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[1].match.post_excerpt.query = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[2].match.post_content.query = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[3].match.post_content_filtered.query = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[4].match.terms.query = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[5].match.post_keywords.query = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[6].match.post_team_status.query = mysearchmini
        .trim()
        .toLowerCase();
      searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[7].regexp.post_title.value = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[8].regexp.post_excerpt.value = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[9].regexp.post_content.value = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[10].regexp.post_content_filtered.value = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[11].regexp.terms.value = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[12].regexp.post_keywords.value = searchMiniQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[13].regexp.post_team_status.value = `.*${mysearchmini
        .trim()
        .toLowerCase()}.*`;

      searchMiniQuery.query.bool.must[2].bool.should[0].match_phrase.language_slug = currentLanguage;

      const today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      searchMiniQuery.query.bool.must[3].bool.should[0].bool.must[0].range.post_date.lte = yyyy + '-' + mm + '-' + dd + ' 23:59:59';
      searchMiniQuery.query.bool.must[3].bool.should[1].bool.must[0].range.date_begin.lte = yyyy + '-' + mm + '-' + dd + ' 23:59:59';

      today.setMonth(today.getMonth() - 6);
      dd = String(today.getDate()).padStart(2, '0');
      mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      yyyy = today.getFullYear();

      searchMiniQuery.query.bool.must[3].bool.should[0].bool.must[0].range.post_date.gte = yyyy + '-' + mm + '-' + dd + ' 00:00:00';
      searchMiniQuery.query.bool.must[3].bool.should[1].bool.must[0].range.date_begin.gte = yyyy + '-' + mm + '-' + dd + ' 00:00:00';

      // console.log( JSON.stringify(searchMiniQuery) );

      searchMiniQuery.size = 3;
      fetch(config.esServer, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          tokenesinouit:
            "spS4brB5EYX2tu8exUPFZDXvAr0ZD7u9whzxPfGqGvGWGcRMDqHxH04axVSj1k0a6TlJTAagi4Sz3yKg5NNPi0F7m2LUSFvZCCQYzMdTS3WXxAXtATBbWVhXwMpSS79PycfnwdabDVsSWttOAWkyCK67DWXq5g1HYTwH8toyF9HyZwrOmttRnYQSf4qnzUJJWYyTAAbvBrdegXFweIdt5IRX0S5GL8ARk3Kz30GWR3tQpxNwrmid9nSe7SQxopoVC7Kl9gR5zQY7ijpeohaEqJZsvg3hN400000iGm8nKB0vKL2i9nPFaLIzVXV6c4RKuWBwzXrBOiCfyAtjyZAe7dpEIuqz82cIh80wiRaVCdOQ9YtE355IqgpiOytsSKTtw6HuWaGjopqjRe9pzixWJWGhLRGPB9iZRe1TP78HuiPdWKHUGWxB5MHroMoTbFqfyLXo9kk4djQA2GuAsTLI8VYOCDwCAcfJOPMtJIwa356KHK8Ma4VCFYgWqJ2sZtT77pOszI8deVGb9M1DXWD9nPhsGJSqDt4K8TRzhcVmgn8sUamx8TFyPa1E06Gh2QUCyXTfjOP0LzSgAdb2gaOohuSOfYibjfoE3aCSnevQAflmGoCqsFphlYNCTzYQhdO2"
        },
        method: "POST",
        body: JSON.stringify(searchMiniQuery)
      })
        .then(response => {
          if (response.status && response.status > 200) {
            console.warn(response);
          } else {
            return response.json();
          }
        })
        .then(({ hits }) => {
          this._isMounted &&
            this.setState({
              totals: ( (hits && hits.total) ? hits.total : 0 ),
              posts: ( (hits && hits.hits) ? hits.hits : 0 ),
              hidePopin: false,
              searchFinish: true
            });
          loadingOff("SearchListMini");
        })
        .catch(function(error) {
          console.error("error", error);
        });
    } else {
      this._isMounted &&
        this.setState({
          totals: false,
          posts: 0
        });
      loadingOff("SearchListMini");
    }
  }

  render = () => {
    const { placeHolderText, disablePopularSearch } = this.props;
    const { posts, searchFinish, mysearchmini, totals, hidePopin } = this.state;

    const { currentLanguage = "fr" } = this.props;
    const languageSearch =
      i18n[currentLanguage] && i18n[currentLanguage].searchPage
        ? i18n[currentLanguage].searchPage
        : "recherche";
    return (
      <div
        className={`search search-mini${
          !hidePopin && (posts.length > 0 || searchFinish)
            ? " search-mini-show"
            : ""
        }`}
        onClick={this.searchListMiniOnClick}
        onKeyPress={this.searchListMiniOnClick}
        role="button"
        tabIndex={0}
      >
        <button className="search-mini-close">X</button>
        <form
          method="GET"
          className="search-form search-form-mini"
          onSubmit={this.searchListMiniSubmit}
          action={`/${languageSearch}/`}
        >
          <div className="search-form-group search-form-mini-group">
            <input
              className="search-form-text search-form-mini-text"
              name="mysearchmini"
              onKeyDown={this.searchListMiniOnKeyDown}
              onKeyUp={this.searchListMiniOnKeyUp}
              placeholder={placeHolderText}
            />
            <span
              className="search-form-submit search-form-mini-submit"
              onClick={this.searchListMiniOnFocus}
              onKeyPress={this.searchListMiniOnFocus}
              role="button"
              tabIndex={0}
            >
              <input
                className="search-form-submit-button search-form-mini-submit-button"
                type="submit"
                onClick={this.searchListMiniOnKeyUp}
              />
            </span>
          </div>
          {!hidePopin &&
            (posts.length === 0 ? (
              <div className="search-form-mini-popin">
                <div className="search-list search-list-mini">
                  <p className="search-list search-list-mini-noresults">
                    Désolé, votre recherche ne correspond à aucun de nos
                    contenus.
                  </p>
                </div>
                {disablePopularSearch !== "true" && <SearchPopularSearch />}
              </div>
            ) : (
              <div className="search-form-mini-popin">
                <div className="search-list search-list-mini">
                  {posts.length &&
                    posts.map((post, index) => (
                      <div key={index}>
                        <SearchMiniListItem postIndex={index} postItem={post} />
                      </div>
                    ))}
                </div>
                <p className="search-list-mini-alllink">
                  <Link
                    to={`/${languageSearch}/${mysearchmini}`}
                    onClick={this.searchListMiniGoSearch}
                  >
                    {totals ? `Voir les ${totals} résultats` : "Aucun résultat"}
                  </Link>
                </p>
              </div>
            ))}
        </form>
      </div>
    );
  };
}

export default connect(
  null,
  loadingActions
)(SearchListMini);
