import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'

import * as actions from '../../state/actions/cookiebox'
import { getCookiebox } from '../../state/reducers/cookiebox'
import LanguageContext from "./../../state/context/LanguageContext";

class Cookiebox extends Component{
  closeCookieBoxEvent = (e) => {
    const {closeCookiebox} = this.props
    
    e.preventDefault()
    closeCookiebox()
  }
  refuseCookieBoxEvent = (e) => {
    const {refuseCookiebox} = this.props
    
    e.preventDefault()
    refuseCookiebox()
  }

  render(){
    const {cookiebox} = this.props
    return(
      <>
        { ( cookiebox !== false && cookiebox !== 'refused' ) &&
          <LanguageContext.Consumer>
            {({ language, translation }) =>
                <div className="cookiebox bg-dark text-white fixed-bottom py-2">
                  <Container>
                    <Row>
                      <Col className="col-lg-10 col-8">
                        {translation.cookieText} <Link to={translation.cookieLink}>{translation.cookieTextLink}</Link>
                      </Col>
                      <Col className="col-lg-2 col-4 align-self-center">
                        <Button color="primary" className="py-0 m-0 mr-lg-1 mb-lg-0 mb-1" onClick={this.closeCookieBoxEvent}>{translation.cookieAcceptText}</Button>
                        <Button color="secondary" className="py-0 m-0 ml-lg-1 mt-lg-0 mt-1" onClick={this.refuseCookieBoxEvent}>{translation.cookieRefuseText}</Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
            }
          </LanguageContext.Consumer>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  cookiebox: getCookiebox(state)
})

export default connect(mapStateToProps, actions)(Cookiebox)
