const emergency = (state = [2636], action) => {
	switch (action.type) {
		case 'CLOSE_EMERGENCY':
			return Array.from(new Set([...state, action.id]))
		default:
			return state
	}
}

export default emergency

export const getEmergency = state => state.emergency
