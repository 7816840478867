import React, { Component } from 'react'

class SearchItemDates extends Component {
	getPostDateBegin = () => {
		if (this.props.postItem._source.date_begin_timestamp) {
			const dateBegin = new Date(this.props.postItem._source.date_begin_timestamp * 1000)
			return new Intl.DateTimeFormat('fr-FR', {day: '2-digit', month: 'long'}).format(dateBegin)
		}
		return ''
	}

	getPostDateEnd = () => {
		if (this.props.postItem._source.date_end_timestamp) {
			const dateEnd = new Date(this.props.postItem._source.date_end_timestamp * 1000)
			const dateEndReturn = Intl.DateTimeFormat('fr-FR', {day: '2-digit', month: 'long'}).format(dateEnd)
			
			const dateBegin = new Date(this.props.postItem._source.date_begin_timestamp * 1000)
			const dateBeginCheck = Intl.DateTimeFormat('fr-FR', {day: '2-digit', month: 'long'}).format(dateBegin)
			if ( dateEndReturn !== dateBeginCheck ) {
				return ' - ' + dateEndReturn
			} else {
				return ''
			}
		}
		return ''
	}

	getPostDatesClass = () => {
		if (this.props.postItemMini) {
			return 'search-list-item-head-date search-list-mini-item-head-date'
		}
		return 'search-list-item-head-date'
	}

	render = () => {
		return (
			<>
				{ this.getPostDateBegin() ? <p className={this.getPostDatesClass()}><span>{this.getPostDateBegin()}</span><span>{this.getPostDateEnd()}</span></p>  : '' }
			</>
		)
	}
}

export default SearchItemDates;
