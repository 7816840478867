export const toggleContrast = () => dispatch => {
	dispatch({
		type: 'TOGGLE_CONTRAST'
	})
}

export const toggleDyslexia = () => dispatch => {
	dispatch({
		type: 'TOGGLE_DYSLEXIA'
	})
}

export const toggleFontSize = () => dispatch => {
	dispatch({
		type: 'TOGGLE_FONT_SIZE'
	})
}