import React, { Component } from 'react'
import { Navbar, Container, Button } from 'reactstrap'
import { Link } from 'gatsby'

import Menu from './Menu'

import logo from '../../../img/logo-roubaix-noir.svg'

class HeaderMobile extends Component{

	constructor(props){
		super(props)
		this.state = {
			menuOpen: false,
			menuHide: false
		}
	}

	toggleMenu = () => {
		this.setState(state => ({
			menuOpen: !state.menuOpen,
			menuHide: false
		}))
	}

	hideMenu = () => {
		this.setState(state => ({
			menuOpen: false,
			menuHide: true
		}))
	}

	render(){
		const {menuOpen, menuHide} = this.state
		return(
			<header className="d-block d-lg-none">
				<Navbar color="primary" className="menu py-3">
					<Container>
						<Button className="navbar-toggler" onClick={this.toggleMenu}/>
						<Link to="/" className="navbar-brand mr-auto ml-auto">
							<img src={logo} alt="Logo Ville de Roubaix"/>
						</Link>
						<Link to="/recherche" className="search-button"/>
					</Container>
				</Navbar>

				<Menu 
					hideMenu={this.hideMenu} 
					toggleMenu={this.toggleMenu} 
					openned={menuOpen} 
					hidden={menuHide}
				/>

			</header>
		)
	}
}

export default HeaderMobile
