import { combineReducers } from 'redux'

const accessibility = () => {
	const contrast = (state = false, action) => {
		switch (action.type) {
			case 'TOGGLE_CONTRAST':
				return !state
			default:
				return state
		}
	}

	const dyslexia = (state = false, action) => {
		switch (action.type) {
			case 'TOGGLE_DYSLEXIA':
				return !state
			default:
				return state
		}
	}

	const fontSize = (state = false, action) => {
		switch (action.type) {
			case 'TOGGLE_FONT_SIZE':
				return !state
			default:
				return state
		}
	}

	return combineReducers({
		contrast,
		dyslexia,
		fontSize
	})
}

export default accessibility

export const getContrast = state => state.accessibility.contrast
export const getDyslexia = state => state.accessibility.dyslexia
export const getFontSize = state => state.accessibility.fontSize