export const iframeParse = str =>
  str
    ? str.replace(
        /<\s*iframe[^>]*>(.*?)<\s*\/\s*iframe>/,
        `
		<div class="border border-primary bg-light p-5 mb-5">
			<span class="h5">Cet élément a besoin des cookies pour fonctionner, vous devez accepter notre utilisation des cookies pour l'afficher en cliquant sur le bouton "Accepter" en bas à droite de votre écran.</span>
		</div>
	`
      )
    : "";

export const arrayContainsArray = (superset, subset) => {
  if (0 === subset.length) return false;
  return subset.every(function(value) {
    return superset.indexOf(value) >= 0;
  });
};

export const inArray = (needle, haystack) => {
  if (haystack && haystack.length > 0) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] === needle) return true;
    }
  }
  return false;
};

export const validateEmail = email => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateField = (value, type, required, number) => {
  if (type === "checkbox") {
    if (number === 1 && required === true && value === false) {
      return false;
    } else {
      return true;
    }
  }
  if (required && (value === null || value === "" || value === undefined))
    return false;
  switch (type) {
    case "email":
      return validateEmail(value);
    default:
      return true;
  }
};

export const htmlDecode = innerHTML => {
  if (typeof document !== "undefined") {
    return Object.assign(document.createElement("textarea"), { innerHTML })
      .value;
  } else {
    return innerHTML;
  }
};

export const isValidUrl = str => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const getIsPwaActive = () =>
  typeof window !== "undefined" &&
  (window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true);
export const getIsIosPwaCapable = () =>
  typeof window !== "undefined" && "standalone" in window.navigator;
export const getIsIosPwaActive = () =>
  typeof window !== "undefined" && window.navigator.standalone === true;
export const getIsAndroidPwaActive = () =>
  typeof window !== "undefined" &&
  window.matchMedia("(display-mode: standalone)").matches;
