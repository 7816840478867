import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider


function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
    return (rect.top > 0)
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {

    if(location && location.state && location.state.menuNavigation && window.innerWidth >= 1279){
        const pageTemplate = document.querySelector(".menu-page-banner"),
            Level2 = document.querySelector(".submenu-container.level-2 .list-menu"),
            Level1 = document.querySelector(".submenu-container.level-1 .menu-level-1 .list-menu")

        if(Level2 && Level2.lastChild){
            if(!isElementInViewport(Level2.lastChild) && pageTemplate && pageTemplate.offsetTop){
                return [0, pageTemplate.offsetTop]
            }
        }else if (Level1 && Level1.lastChild){
            if(!isElementInViewport(Level1.lastChild) && pageTemplate && pageTemplate.offsetTop){
                return [0, pageTemplate.offsetTop]
            }
        }
        return false
    }
    else
        return true
}