import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import { Container, Nav, NavItem, NavLink, Row, Col, Button } from "reactstrap";

import { addresses } from "../../../utils/variables";

import i18n from "./../../../i18n/i18n.json";

import LanguageContext from "./../../../state/context/LanguageContext";

const Menu = ({ openned, hidden, toggleMenu, hideMenu }) => {
  const languageSlugs = Object.keys(i18n).map(l => i18n[l].slug);
  return (
    <LanguageContext.Consumer>
      {({ language, translation }) => (
        <div
          className={`menu-mobile-0 py-5${hidden ? " hidden" : ""}${
            openned ? " openned" : " closed"
          }`}
        >
          <Container>
            <Row>
              <Col>
                <Button className="menu-close ml-auto" onClick={toggleMenu} />
              </Col>
            </Row>
            <Nav className="mr-auto flex-shrink-0 main" navbar>
              <StaticQuery
                query={graphql`
                  query {
                    menu: allWordpressPage(
                      filter: { wordpress_parent: { eq: 0 } }
                    ) {
                      edges {
                        node {
                          title
                          full_slug
                          wordpress_id
                          menu_order
                          hide_menu
                          language
                        }
                      }
                    }
                  }
                `}
                render={({ menu }) =>
                  menu.edges
                    .filter(
                      ({ node }) =>
                        !node.hide_menu &&
                        node.language === language &&
                        !languageSlugs.includes(node.full_slug.slice(1, -1))
                    )
                    .sort((a, b) => a.node.menu_order - b.node.menu_order)
                    .map(({ node }) => (
                      <NavItem key={node.wordpress_id}>
                        <Link
                          className="nav-link py-3"
                          to={node.full_slug}
                          dangerouslySetInnerHTML={{ __html: node.title }}
                          onClick={hideMenu}
                        />
                      </NavItem>
                    ))
                }
              />
            </Nav>

            <Nav navbar className="social mt-4">
              <NavItem className="align-items-center">
                <NavLink
                  href={addresses.instagram}
                  className="icon-link d-block text-center text-dark rounded-circle mr-2 p-0"
                >
                  <i className="icon-instagram" />
                </NavLink>
              </NavItem>
              <NavItem className="align-items-center">
                <NavLink
                  href={addresses.facebook}
                  className="icon-link d-block text-center text-dark rounded-circle mr-2 p-0"
                >
                  <i className="icon-facebook" />
                </NavLink>
              </NavItem>
              <NavItem className="align-items-center">
                <NavLink
                  href={addresses.twitter}
                  className="icon-link d-block text-center text-dark rounded-circle mr-2 p-0"
                >
                  <i className="icon-twitter" />
                </NavLink>
              </NavItem>
            </Nav>

            <Nav navbar className="links mt-5">
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixxl}
                  className="pl-5 py-4 logo-roubaixxl d-block"
                >
                  {translation.actusmobile}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixtv}
                  className="pl-5 py-4 logo-roubaix-ontv d-block"
                >
                  Roubaix on TV
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixviva}
                  className="pl-5 py-4 logo-vivacite d-block"
                >
                  {translation.vivacite}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixdemarche}
                  className="pl-5 py-4 logo-demarche d-block"
                >
                  {translation.demarche}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixdechet}
                  className="pl-5 py-4 logo-dechet d-block"
                >
                  {translation.zerodechet}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixcarte}
                  className="pl-5 py-4 logo-map d-block"
                >
                  {translation.carte}
                </NavLink>
              </NavItem>
              <NavItem>
                <Link to="liens" className="logo-fournisseur d-block nav-link">
                  {translation.alllinks}
                </Link>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixemploi}
                  className="logo-emploi d-block"
                >
                  {translation.jobs}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                  href={addresses.roubaixfournisseur}
                  className="logo-fournisseur d-block"
                >
                  {translation.providers}
                </NavLink>
              </NavItem>
            </Nav>
          </Container>
        </div>
      )}
    </LanguageContext.Consumer>
  );
};

Menu.contextType = LanguageContext;
export default Menu;
