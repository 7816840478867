import React, { Component } from 'react'

class SearchItemTeamStatus extends Component {
	getPostTeamStatus = () => {
		if (this.props.postItem.highlight && this.props.postItem.highlight.post_team_status) {
			return String(this.props.postItem.highlight.post_team_status)
		} else if (this.props.postItem._source.post_team_status) {
			return String(this.props.postItem._source.post_team_status)
		}
		return ''
	}

	getPostTeamStatusClass = () => {
		if (this.props.postItemMini) {
			return 'search-list-item-fonction search-list-mini-item-fonction'
		}
		return 'search-list-item-fonction'
	}

	render = () => {
		return (
			<>
				{ this.getPostTeamStatus() ? <p className={this.getPostTeamStatusClass()} >{this.getPostTeamStatus()}</p> : '' }
			</>
		)
	}
}

export default SearchItemTeamStatus;
