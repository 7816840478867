import React from 'react'
import { connect } from 'react-redux'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'

import * as actions from '../../state/actions/emergency'
import {getEmergency} from '../../state/reducers/emergency'

import megaphone from '../../img/icons/megaphone.svg'

const Emergency = ({emergency, closeEmergency}) =>
  <StaticQuery
    query={graphql`
      query {
        emergencies: allWordpressWpEmergency{
          edges{
            node{
              wordpress_id
              title
              emergency_link{
                url
                title
                target
              }
            }
          }
        }
      }
    `}
    render={({emergencies}) => {
      const {wordpress_id, emergency_link, title} = emergencies.edges[0].node

      return <>
        {emergency && Array.isArray(emergency) && !emergency.includes(wordpress_id) &&
          <div className="emergency py-2">
            <Container>
              <Row>
                <Col className="d-flex align-items-center">
                  <img className="flex-shrink-0 flex-grow-0 mr-3" src={megaphone} alt="icone des alertes"/>
                  <div>
                    {title}
                    {emergency_link &&
                      <a
                        className="text-primary ml-2"
                        href={emergency_link.url}
                        target={emergency_link.target || '_self'}
                        title={ emergency_link.title || 'En savoir plus' }
                        rel={emergency_link.target === '_blank' ? "noopener noreferrer" : ""}
                      >
                        { emergency_link.title || 'En savoir plus' }
                      </a>
                    }
                  </div>
                </Col>
                <Col className="flex-shrink-0 flex-grow-0 align-self-center">
                  <Button close onClick={() => closeEmergency(wordpress_id)}>
                    <span aria-hidden="true">×</span>
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        }
      </>
    }}
  />

  

const mapStateToProps = state => ({
  emergency: getEmergency(state)
})

export default connect(mapStateToProps, actions)(Emergency)
