import { createStore, applyMiddleware, compose } from "redux"
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import { createLogger } from 'redux-logger'
import moment from 'moment'

import rootReducer from './reducers'
import { loadState, saveState } from './localStorage'

const configureStore = () => {
	const middlewares = [thunk]
	const persistedState = loadState()
	const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

	process.env.NODE_ENV !== 'production' && middlewares.push(createLogger())

	const store = createStore(
		rootReducer,
		persistedState,
		composeEnhancers(applyMiddleware(...middlewares))
	)

	store.subscribe(throttle(() => {
		saveState({
			accessibility: store.getState().accessibility,
			emergency: store.getState().emergency,
			agenda: store.getState().agenda,
			cookiebox: store.getState().cookiebox
		}, moment().add(1, 'year').unix())
	}, 1000))

	return store
}

export default configureStore
