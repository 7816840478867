export const closeCookiebox = () => dispatch => {
	dispatch({
		type: 'CLOSE_COOKIE_BOX'
	})
}

export const refuseCookiebox = () => dispatch => {
	dispatch({
		type: 'REFUSED_COOKIE_BOX'
	})
}

export const showCookiebox = () => dispatch => {
	dispatch({
		type: 'SHOW_COOKIE_BOX'
	})
}
