import React from 'react'

const getPostCatName = {
	'news-roubaixxxl': 'Actu Roubaixxl.fr',
	'events-openagenda': 'Evénement',
	'page': 'Contenu'
}

const SearchItemCatName = ({postItem, postItemMini}) =>
	<>
		{postItem && postItem._source && postItem._source.post_type &&
			<p className={`search-list-item-head-cateory ${postItemMini ? 'search-list-mini-item-head-category' : ''}`}>
				<span dangerouslySetInnerHTML={{ __html: postItem._source.post_type in getPostCatName ? getPostCatName[postItem._source.post_type] : 'Annuaire' }}></span>
			</p> 
		}
	</>

export default SearchItemCatName