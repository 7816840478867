import React from 'react';
import {Link} from 'gatsby'

const SearchPopularSearch = () => (
	<div className="search-popular-search">
		<p className="search-popular-search-title">Recherches populaires : </p>
		<ul className="search-popular-search-list">
			<li className="search-popular-search-list-item"><Link to={`/services-infos-pratiques/demarches/etat-civil/`}>Etat civil</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/services-infos-pratiques/demarches/demarches-en-ligne-accompagnement/`}>Démarches en ligne</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/services-infos-pratiques/services-mairies-de-quartiers/annuaire-des-services-municipaux/`}>Services municipaux</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/services-infos-pratiques/social-solidarites`}>Social</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/municipalite/`}>Les élus</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/services-infos-pratiques/sports/`}>Sports</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/services-infos-pratiques/proprete/la-gestion-des-dechets/les-encombrants-vegetaux-et-dechets-speciaux/`}>Encombrants</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/professionnels/marches-braderies-brocantes/`}>Marchés - brocantes</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/services-infos-pratiques/education-enfance/autour-de-lecole/restauration-scolaire/`}>Restauration scolaire</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/professionnels/marches-publics/`}>Marchés publics</Link></li>
			<li className="search-popular-search-list-item"><Link to={`/agenda/`}>Agenda</Link></li>
			<li className="search-popular-search-list-item"><a href={`https://services.ville-roubaix.fr/espaceEmploi`} rel="noopener noreferrer" target="_blank">Recrutement</a></li>
		</ul>
	</div>
)

export default SearchPopularSearch;
