import Config_local from "../config/Config_local";

export const sdkvox = () => {
    if ( document.getElementById('voxScript') ) {
        document.body.removeChild( document.getElementById('voxScript') )
    }
    const voxScript = document.createElement("script");
    voxScript.src = Config_local.apiServer + "/wp-content/themes/skin/assets/js/vox.js";
    voxScript.async = true;
    voxScript.id = 'voxScript';
    document.body.appendChild(voxScript);
}