const anchors = (state = {}, action) => {
	switch (action.type) {
		case 'ANCHORS_INIT':
			return action.anchors
		case 'ANCHOR_UPDATE':
			const newAnchor = {}
			newAnchor[action.slug] = {...state[action.slug], active: action.active}
			return {...state, ...newAnchor}
		default:
			return state
	}
}

export default anchors

export const getAnchors = state => state.anchors