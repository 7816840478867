import moment from 'moment'

export const loadState = () => {
	if(typeof window !== 'undefined'){
		try{
			const expires = localStorage.getItem('expires')
			const serializedState = localStorage.getItem('state')
			if(moment.unix(expires).isBefore(moment().unix()) || moment.unix(expires) < moment.unix(1573599600) || serializedState === null){
				return undefined
			}
			return JSON.parse(serializedState)
		}catch(err){
			console.error(err)
		}
	}
}

export const saveState = (state, expires) => {
	if(typeof window !== 'undefined'){
		try{
			const serializedState = JSON.stringify(state)
			localStorage.setItem('state', serializedState)
			localStorage.setItem('expires', expires)
		}catch(err){
			console.error(err)
		}
	}
}