import React, { Component } from 'react'

import SearchItemCatName from './Items/SearchItemCatName'
import SearchItemDates from './Items/SearchItemDates'
import SearchItemTitle from './Items/SearchItemTitle'
import SearchItemExcerpt from './Items/SearchItemExcerpt'
import SearchItemTeamStatus from './Items/SearchItemTeamStatus'
// import SearchItemScore from './Items/SearchItemScore'

class SearchItem extends Component {
	getPostType = () => {
		if (this.props.postItem._source.post_type) {
			return "search-list-item search-list-mini-item search-list-mini-item-"+String(this.props.postItem._source.post_type)+" search-list-item-"+String(this.props.postItem._source.post_type)
		}
		return ''
	}

	render = () => {
		return (
			<div key={this.props.postIndex} className={this.getPostType()}>
				<div className="search-list-item-head search-list-mini-item-head">
					<SearchItemCatName postItem={this.props.postItem} postItemMini="true" />
					<SearchItemDates postItem={this.props.postItem} postItemMini="true" />
				</div>
				<SearchItemTitle postItem={this.props.postItem} postItemMini="true" />
				<SearchItemExcerpt postItem={this.props.postItem} postItemMini="true" />
				<SearchItemTeamStatus postItem={this.props.postItem} postItemMini="true" />
			</div>
		)
	}
}

export default SearchItem;
