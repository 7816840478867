import React from 'react'

const formatStr = (str, postItemMini) => {
	const size = postItemMini ? 50 : 300
	const newStr = str.replace(/<(?:.|\n)*?>/gm, '').substr(0, size)
	return `${newStr.substr(0, Math.min(newStr.length, newStr.lastIndexOf(" ")))}...`
}

const SearchItemExcerpt = ({postItem, postItemMini}) =>
	<>
		{postItem.highlight && postItem.highlight.post_excerpt &&
			<p
				className={`search-list-item-description ${postItemMini ? 'search-list-mini-item-description' : ''}`}
				dangerouslySetInnerHTML={{ __html: postItem.highlight.post_excerpt }}
			></p>
		}
		{!postItem.highlight && postItem._source.post_excerpt &&
			<p
				className={`search-list-item-description ${postItemMini ? 'search-list-mini-item-description' : ''}`}
				dangerouslySetInnerHTML={{ __html: formatStr(postItem._source.post_excerpt, postItemMini) }}
			></p>
		}
		{!postItem.highlight && !postItem._source.post_excerpt && postItem._source.post_content_filtered &&
			<p
				className={`search-list-item-description ${postItemMini ? 'search-list-mini-item-description' : ''}`}
				dangerouslySetInnerHTML={{ __html: formatStr(postItem._source.post_content_filtered, postItemMini) }}
			></p>
		}
	</>

export default SearchItemExcerpt