export const addresses = {
  roubaixxl: "https://www.roubaixxl.fr/",
  roubaixtv: "https://www.ville-roubaix.tv/",
  roubaixviva: "/services-infos-pratiques/proprete/vivacite/",
  roubaixdemarche: "https://services.ville-roubaix.fr/demarches/jsp/puaccueil.jsp",
  roubaixdechet: "https://www.roubaixzerodechet.fr/",
  roubaixavantposte: "https://avantposte-roubaix.fr/",
  roubaixemplois: "https://roubaixemploi.fr/",
  roubaixcarte: "https://carto.ville-roubaix.fr/portal/apps/webappviewer/index.html?id=0352e182047b47be8ccbbaa410115649",
  roubaixemploi: "https://services.ville-roubaix.fr/espaceEmploi",
  roubaixfournisseur: "http://portail.ville-roubaix.fr/espacefournisseur/jsp/ef3accueil.jsp",
  instagram: "https://www.instagram.com/roubaix.fr/",
  facebook: "https://www.facebook.com/mairie.roubaix",
  twitter: "https://twitter.com/roubaix",
  periscope: "https://www.roubaixxl.fr/roubaix-est-aussi-sur-periscope/",
  pinterest: "https://fr.pinterest.com/roubaixfr/",
  youtube: "https://www.youtube.com/user/RbxonwebTV",
  flickr: "https://www.flickr.com/photos/roubaixfr/",
  snapchat: "https://www.roubaixxl.fr/roubaix-est-sur-snapchat/",
  linkedin: "https://www.linkedin.com/company/1576211",
  tiktok: "https://www.tiktok.com/@roubaix.fr",
  threads: "https://www.threads.net/@roubaix.fr",
  commingsoon: "/coming-soon/",
  binnenkort: "/komt-binnenkort/"
};
