import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import {
  Container,
  Nav,
  Navbar,
  NavItem,
  NavLink
  // InputGroup,
  // InputGroupAddon,
  // Input,
  // Button
} from "reactstrap";

import SearchListMini from "../Search/SearchListMini";

import { addresses } from "./../../utils/variables";
import LanguageContext from "./../../state/context/LanguageContext";
import i18n from "./../../i18n/i18n.json";

const isPartiallyActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent && { className: "nav-link text-white active" };

const Menu = () => {
  const languageSlugs = Object.keys(i18n).map(l => i18n[l].slug);

  return (
    <LanguageContext.Consumer>
      {({ language, translation }) => (
        <Navbar color="primary" expand="md" className="menu p-0">
          <Container>
            <Nav className="mr-auto flex-shrink-0" navbar>
              <StaticQuery
                query={graphql`
                  query {
                    menu: allWordpressPage(
                      filter: { wordpress_parent: { eq: 0 } }
                    ) {
                      edges {
                        node {
                          title
                          full_slug
                          wordpress_id
                          menu_order
                          hide_menu
                          language
                        }
                      }
                    }
                  }
                `}
                render={({ menu }) =>
                  menu.edges
                    .sort((a, b) => a.node.menu_order - b.node.menu_order)
                    .filter(
                      ({ node }) =>
                        !node.hide_menu &&
                        node.language === language &&
                        !languageSlugs.includes(node.full_slug.slice(1, -1))
                    )
                    .map(({ node }) => (
                      <NavItem key={node.wordpress_id}>
                        <Link
                          className="nav-link text-white"
                          to={node.full_slug}
                          getProps={isPartiallyActive}
                          dangerouslySetInnerHTML={{ __html: node.title }}
                        />
                      </NavItem>
                    ))
                }
              />
              <NavItem className={language !== 'fr' ? 'd-none' : ''}>
                <NavLink
                  href={addresses.roubaixxl}
                  className="text-white link-xxl"
                  target="_blank"
                >
                  {translation.actutitle} <small>{translation.actusmail}</small>
                </NavLink>
              </NavItem>
            </Nav>
            <div className="ml-left">
              <SearchListMini
                currentLanguage={language}
                placeHolderText={translation.searchPlaceholder}
              />
            </div>
          </Container>
        </Navbar>
      )}
    </LanguageContext.Consumer>
  );
};

Menu.contextType = LanguageContext;
export default Menu;
