export const sarbacane = () => {
    if ( document.getElementById('sbcScript') ) {
        document.body.removeChild( document.getElementById('sbcScript') )
    }
    const sbcScript = document.createElement("script");
    sbcScript.src = 'https://forms.sbc08.com/form.js';
    sbcScript.async = true;
    sbcScript.id = 'sbcScript';
    document.body.appendChild(sbcScript);

    var sbForm = document.querySelector('#sb_form')
    if ( sbForm ) {
        sbForm.addEventListener( 'submit', function( event ){
            event.preventDefault();
            if (typeof process2 === 'function') {
                window.process2(
                    'https://services.sarbacane.com/core/v1/forms/contacts/upsert?listID&#x3D;KpekbOX4QByqY-tgAgYSIg&amp;formID&#x3D;QJnypT4iShi8jEJf0TeGmA&amp;timezone&#x3D;Europe/Paris',
                    'https://forms.sbc08.com/',
                    '61ea6c96c6ff2437e5e5acee',
                    'true',
                    'url',
                    'https://www.ville-roubaix.fr/inscription-newsletter-reussie/',
                    'https://services.sarbacane.com/core/v1/transactional/sendmessage/optin',
                    'Merci',
                    'Vos informations ont été ajoutées avec succès.',
                    'Vous allez recevoir un email',
                    'Vous devrez cliquer sur le lien de confirmation pour valider votre inscription',
                    'Erreur',
                    'Une erreur inattendue s%27est produite.',
                    'Le formulaire est en cours d%27édition, veuillez patienter quelques minutes avant d%27essayer à nouveau.',
                    'QJnypT4iShi8jEJf0TeGmA',
                    'FR',
                    'Sarbacane'
                  );
            }
            return false;
        })
    }
}