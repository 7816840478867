export const loadingOn = item => dispatch => {
	dispatch({
		type: 'LOADING_ON',
		item
	})
}

export const loadingOff = item => dispatch => {
	dispatch({
		type: 'LOADING_OFF',
		item
	})
}