import React from "react";

import SiteHelmet from "./SiteHelmet";
import BrowserCheck from "../BrowserCheck";
import Header from "../Header";
import Footer from "../Footer";
import Loading from "../Loading";
import PwaPrompt from "../PwaPrompt";
import Emergency from "../Emergency";
import Cookiebox from "../Cookiebox";

import LanguageContext from "./../../state/context/LanguageContext";
import getTranslations from "./../../i18n/getTranslations";
import "../../css/styles.css";

const DefaultLayout = ({ children, pageContext }) => {
  const language =
    pageContext && pageContext.language ? pageContext.language : "fr";

  return (
    <LanguageContext.Provider
      value={{ language, translation: getTranslations(language) }}
    >
      <div className="layout default-layout d-flex flex-column justify-content-between h-100 mh-100vh">
        <SiteHelmet />
        <BrowserCheck />
        <Loading />
        <PwaPrompt />
        {language === "fr" && <Emergency />}
        <Header />
        <main className="flex-grow-1">{children}</main>
        <Footer />
        <Cookiebox />
      </div>
    </LanguageContext.Provider>
  );
};

export default DefaultLayout;
