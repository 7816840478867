const agenda = (state = '', action) => {
	switch (action.type) {
		case 'AGENDA_FILTER_OTHER':
			return state
		default:
			return state
	}
}

export default agenda

export const getAgenda = state => state.agenda
