import { combineReducers } from 'redux'

import anchors from './anchors'
import accessibility from './accessibility'
import loading from './loading'
import emergency from './emergency'
import agenda from './agenda'
import cookiebox from './cookiebox'
import pwaPrompt from './pwaPrompt'

const appReducers =  combineReducers({
	anchors,
	accessibility: accessibility(),
	loading,
	emergency,
	agenda,
	cookiebox,
	pwaPrompt
})

const rootReducer = (state, action) => appReducers(state, action)

export default rootReducer
