import React from 'react'

import HeaderTop from './HeaderTop'
import Menu from './Menu'

import HeaderMobile from './Mobile'

const Header = () =>
	<>
		<header className="d-none d-lg-block">
			<HeaderTop />
			<Menu />
		</header>
		<HeaderMobile />
	</>



export default Header