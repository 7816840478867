import React, { Component } from "react"
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { getContrast, getFontSize, getDyslexia } from '../../state/reducers/accessibility'
import { matomo } from '../../config/matomo'
import { sdkvox } from '../../config/sdkvox'
import { sarbacane } from '../../config/sarbacane'

import splash2048 from '../../img/apple_splash_2048.png'
import splash1668 from '../../img/apple_splash_1668.png'
import splash1536 from '../../img/apple_splash_1536.png'
import splash1125 from '../../img/apple_splash_1125.png'
import splash1242 from '../../img/apple_splash_1242.png'
import splash750 from '../../img/apple_splash_750.png'
import splash640 from '../../img/apple_splash_640.png'

class SiteHelmet extends Component{
	componentDidMount(){
		matomo()
		sdkvox()
		sarbacane()
	}

	render(){
		const {contrast, fontSize, dyslexia} = this.props

		return(
			<Helmet htmlAttributes={{'class': `${contrast ? 'contrast' : ''} ${fontSize ? 'font125x' : ''} ${dyslexia ? 'open-dyslexic' : ''}`}}>
				<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700" rel="stylesheet"/>
				
				<link rel="apple-touch-icon" sizes="48x48" href="/icons/icon-48x48.png" />
				<link rel="apple-touch-icon" sizes="72x72" href="/icons/icon-72x72.png" />
				<link rel="apple-touch-icon" sizes="96x96" href="/icons/icon-96x96.png" />
				<link rel="apple-touch-icon" sizes="144x144" href="/icons/icon-144x144.png" />
				<link rel="apple-touch-icon" sizes="192x192" href="/icons/icon-192x192.png" />
				<link rel="apple-touch-icon" sizes="256x256" href="/icons/icon-256x256.png" />
				<link rel="apple-touch-icon" sizes="384x384" href="/icons/icon-384x384.png" />
				<link rel="apple-touch-icon" sizes="512x512" href="/icons/icon-512x512.png" />

				<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>
				<meta name="apple-mobile-web-app-capable" content="yes" />

				<link rel="apple-touch-startup-image" href={splash640} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" />
				<link rel="apple-touch-startup-image" href={splash750} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" />
				<link rel="apple-touch-startup-image" href={splash1242} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)" />
				<link rel="apple-touch-startup-image" href={splash1125} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" />
				<link rel="apple-touch-startup-image" href={splash1536} media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)" />
				<link rel="apple-touch-startup-image" href={splash1668} media="(min-device-width: 834px) and (max-device-width: 834px) and (-webkit-min-device-pixel-ratio: 2)" />
				<link rel="apple-touch-startup-image" href={splash2048} media="(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)" />
			</Helmet>
		)
	}
}

const mapStateToProps = state => ({
	contrast: getContrast(state),
	fontSize: getFontSize(state),
	dyslexia: getDyslexia(state)
})

export default connect(mapStateToProps)(SiteHelmet)
