import React from 'react'

const { detect } = require('detect-browser')
const browser = detect()
const linkUpdateNav = 'http://www.updateyourbrowser.net/fr'		
const showBoxInfos = browser && browser.os.indexOf('Windows') !== false && browser.name === 'ie' && parseInt(browser.version) < 11

const BrowserCheck = () =>
	<>
		{showBoxInfos &&
			<div className="browser-check-alert-message alert alert-danger" role="alert">
				Votre navigateur est obsolète! Vous êtes en train d'utiliser une ancienne version de <strong>{browser.name}</strong> ({browser.version}) . Pour la meilleure experience,  s'il vous plaît <a className="alert-link" href={linkUpdateNav} target="_blank" rel="noopener noreferrer">Actualisez votre navigateur</a>
			</div>
		}
	</>

export default BrowserCheck