const cookiebox = (state = true, action) => {
	switch (action.type) {
		case 'CLOSE_COOKIE_BOX':
			return false
		case 'SHOW_COOKIE_BOX':
			return true
		case 'REFUSED_COOKIE_BOX':
			return 'refused'
		default:
			return state
	}
}

export default cookiebox

export const getCookiebox = state => state.cookiebox
