import React, { Component } from 'react'
import { Media, Button } from 'reactstrap'

import { getIsIosPwaCapable, getIsIosPwaActive } from '../../utils/functions'

import icon from '../../img/icon.png'
import add from '../../img/add.png'
import share from '../../img/share.png'

class PwaPrompt extends Component{
	_isMounted = false

	constructor(props){
		super(props)

		this.state = {
			isOpen: false
		}
	}

	componentDidMount(){
		this._isMounted = true
		setTimeout(() => this._isMounted && this.setState({isOpen: true}), 20000)
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	closePrompt = () => {
		this.setState({isOpen: false})
	}

	render(){
		const iconStyle = {
			width: '3rem',
			height: '3rem'
		}
		return (
			<>
				{getIsIosPwaCapable() && !getIsIosPwaActive() &&
					<div className={`ios-pwa-prompt fixed-top bg-light rounded p-3${this.state.isOpen ? ' show' : ''}`}>
						<Media className="d-flex align-items-center">
							<Media left className="mr-3">
								<Media object src={icon} alt="Icone de l'application" style={iconStyle} />
							</Media>
							<Media body>
								<Button close onClick={this.closePrompt}>
									<span aria-hidden="true">×</span>
								</Button>
								Installez l'application sur iOS:<br />
								touchez <img src={share} alt="illustration partage iOS"/> puis <img src={add} alt="illustration ajouter à accueil"/> <strong>Sur l'écran d'accueil</strong>
							</Media>
						</Media>
					</div>
				}
			</>
		)
	}
}

export default PwaPrompt
