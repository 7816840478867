import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";

import * as actions from "../../state/actions/accessibility";
import { addresses } from "../../utils/variables";
import logo from "../../img/logo-roubaix-blanc.svg";
// import iconPeriscope from "../../img/icons/picto-periscope.svg";
import iconYoutube from "../../img/icons/picto-youtube.svg";
import iconPinterest from "../../img/icons/picto-pinterest.svg";
import iconTiktok from "../../img/icons/picto-tiktok.svg";
// import iconFlickr from "../../img/icons/picto-flickr.svg";
import iconSnapchat from "../../img/icons/picto-snapchat.svg";
import iconLinkedin from "../../img/icons/linked-in.svg";
import iconThreads from "../../img/icons/picto-threads.svg";

import LanguageContext from "./../../state/context/LanguageContext";
import i18n from "./../../i18n/i18n.json";

const defaultState = {
  dropdownSitesOpen: false,
  dropdownSocialOpen: false,
  dropdownAccessibilityOpen: false,
  dropdownLangOpen: false
};

class HeaderTop extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultState };
  }

  toggleSites = () => {
    this.setState({
      ...defaultState,
      dropdownSitesOpen: !this.state.dropdownSitesOpen
    });
  }
  toggleSocial = () => {
    this.setState({
      ...defaultState,
      dropdownSocialOpen: !this.state.dropdownSocialOpen
    });
  }
  toggleAccessibility = () => {
    this.setState({
      ...defaultState,
      dropdownAccessibilityOpen: !this.state.dropdownAccessibilityOpen
    });
  }
  toggleLang = () => {
    this.setState({
      ...defaultState,
      dropdownLangOpen: !this.state.dropdownLangOpen
    });
  }

  toggleContrast = () => {
    this.props.toggleContrast();
  }
  toggleFontSize = () => {
    this.props.toggleFontSize();
  }
  toggleDyslexia = () => {
    this.props.toggleDyslexia();
  }

  render() {
    return (
      <LanguageContext.Consumer>
        {({ language, translation }) => {
          const languageShortName =
            language &&
            Object.keys(i18n) &&
            Object.keys(i18n).includes(language)
              ? i18n[language].shortName
              : "Fr";
          const homeUrl = language === "fr" ? "/" : `/${language}`;
          return (
            <Navbar
              color="dark"
              expand="md"
              className="header-top py-3 py-lg-1"
              id="pagetop"
            >
              <Container>
                <Link to={homeUrl} className="navbar-brand pr-4">
                  <img src={logo} alt="Logo Ville de Roubaix" />
                </Link>
                <Nav className="mr-auto nav-sites" navbar>
                  <Dropdown
                    nav
                    isOpen={this.state.dropdownSitesOpen}
                    toggle={this.toggleSites}
                  >
                    <DropdownToggle nav caret>
                      {translation.allsites}
                    </DropdownToggle>
                    <DropdownMenu className="nav-sites-dropdown" left="auto">
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-link nav-sites-xxl"
                          href={addresses.roubaixxl}
                        >
                          RoubaiXXL
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-link nav-sites-rbxtv"
                          href={addresses.roubaixtv}
                        >
                          Roubaix on TV
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          className="nav-sites-link nav-sites-viva"
                          href={addresses.roubaixviva}
                        >
                          {translation.vivacite}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-link nav-sites-demarches"
                          href={addresses.roubaixdemarche}
                        >
                          {translation.demarche}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-link nav-sites-rbxdechet"
                          href={addresses.roubaixdechet}
                        >
                          {translation.zerodechet}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-link nav-sites-avantposte"
                          href={addresses.roubaixavantposte}
                        >
                          {translation.avantposte}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-link nav-sites-rbxemplois"
                          href={addresses.roubaixemplois}
                        >
                          {translation.roubaixemplois}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-link nav-sites-carte"
                          href={addresses.roubaixcarte}
                        >
                          {translation.carte}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <Link
                          className="nav-sites-btn nav-link mb-3"
                          to="liens"
                        >
                          {translation.alllinks}
                        </Link>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-btn nav-sites-btn-alt"
                          href={addresses.roubaixemploi}
                        >
                          {translation.jobs}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem tag="div">
                        <NavLink
                          target="_blank"
                          className="nav-sites-btn"
                          href={addresses.roubaixfournisseur}
                        >
                          {translation.providers}
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </Nav>
                <div className="d-flex ml-auto">
                  <Nav navbar className="nav-social">
                    <NavItem className="d-flex align-items-center">
                      <NavLink
                        target="_blank"
                        href={addresses.facebook}
                        className="icon-link d-block text-center text-dark rounded-circle mr-1 p-0"
                      >
                        <i className="icon-facebook" />
                      </NavLink>
                    </NavItem>
                    <NavItem className="d-flex align-items-center">
                      <NavLink
                        target="_blank"
                        href={addresses.twitter}
                        className="icon-link d-block text-center text-dark rounded-circle mr-1 p-0"
                      >
                        <i className="icon-twitter" />
                      </NavLink>
                    </NavItem>
                    <NavItem className="d-flex align-items-center">
                      <NavLink
                        target="_blank"
                        href={addresses.instagram}
                        className="icon-link d-block text-center text-dark rounded-circle mr-1 p-0"
                      >
                        <i className="icon-instagram" />
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Nav navbar>
                    <Dropdown
                      nav
                      className="nav-more pr-3 mr-2"
                      isOpen={this.state.dropdownSocialOpen}
                      toggle={this.toggleSocial}
                    >
                      <DropdownToggle nav caret>
                        {translation.more}
                      </DropdownToggle>
                      <DropdownMenu className="nav-social-dropdown">
                        <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.youtube}
                          >
                            <img src={iconYoutube} alt="" />
                          </a>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.pinterest}
                          >
                            <img src={iconPinterest} alt="" />
                          </a>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.tiktok}
                          >
                            <img src={iconTiktok} alt="" />
                          </a>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.snapchat}
                          >
                            <img src={iconSnapchat} alt="" />
                          </a>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.linkedin}
                          >
                            <img src={iconLinkedin} alt="" />
                          </a>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.threads}
                          >
                            <img src={iconThreads} alt="" />
                          </a>
                        </DropdownItem>
                        {/* <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.periscope}
                          >
                            <img src={iconPeriscope} alt="" />
                          </a>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={addresses.flickr}
                          >
                            <img src={iconFlickr} alt="" />
                          </a>
                        </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </Nav>

                  <Nav navbar className="navbar-accessibility">
                    <Dropdown
                      nav
                      className="nav-more pr-3 mr-2"
                      isOpen={this.state.dropdownAccessibilityOpen}
                      toggle={this.toggleAccessibility}
                    >
                      <DropdownToggle nav caret>
                        <i className="icon-accessibility" />{" "}
                        {translation.headerAccessibility}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem tag="div">
                          <Button color="dark" onClick={this.toggleContrast}>
                            <i className="icon-brightness_medium" />{" "}
                            {translation.contraste}
                          </Button>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <Button
                            color="link"
                            className="fontx2"
                            onClick={this.toggleFontSize}
                          >
                            <i className="icon-format_size" />{" "}
                            {translation.textsize}
                          </Button>
                        </DropdownItem>
                        <DropdownItem tag="div">
                          <Button
                            color="link"
                            className="open-dyslexic"
                            onClick={this.toggleDyslexia}
                          >
                            {translation.dyslexie}
                          </Button>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Nav>

                  <Nav navbar className="navbar-lang">
                    <Dropdown
                      nav
                      isOpen={this.state.dropdownLangOpen}
                      toggle={this.toggleLang}
                    >
                      <DropdownToggle nav caret>
                        {languageShortName}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {Object.keys(i18n).map((l, key) => (
                          <DropdownItem tag="div" key={key}>
                            <NavLink href={i18n[l].home} className="btn-link">
                              {i18n[l].shortName}
                            </NavLink>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Nav>
                </div>
              </Container>
            </Navbar>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}

export default connect(
  null,
  actions
)(HeaderTop);
