const loading = (state = [], action) => {
	switch (action.type) {
		case 'LOADING_ON':
			return Array.from(new Set([...state, action.item]))
		case 'LOADING_OFF':
			return state.filter(e => e!== action.item)
		default:
			return state
	}
}

export default loading

export const getLoading = state => state.loading